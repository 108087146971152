const defaultConfig = {
  signIn: {
    url:  null
  },
  signOut: {
    url:  null
  },
  userIsAuthenticated: false,
  menu: []
}

const config = {
  ...defaultConfig,
  ...window.seaBrandConfig?.account
}

// Set Default Text Here, because objects aren't getting deeply merged.
if (!config.signIn.text)  config.signIn.text = 'Sign In'
if (!config.signOut.text) config.signOut.text = 'Sign Out'

const cleanText = string => string.replace(/[^\w\s]/gi, '')


const allowed = () => {
  const { signIn, signOut, userIsAuthenticated, menu } = config

  const cansignIn = (signIn.url && signIn.text) ? true : false

  // console.log('signIn Allowed: ', { signIn })

  return cansignIn
}

const menuParts = () => {
  return {
    menu: document.getElementById('sgMenu'),
    overlay: document.getElementById('sgOverlay'),
    open: document.getElementById('sgMenuOpen'),
    close: document.getElementById('sgMenuClose')
  }
}

const openMenu = () => {
  const { menu, overlay, close } = menuParts()

  menu.setAttribute('aria-expanded', true)
  menu.classList.add('active')
  overlay.classList.add('active')

  close.focus()
}

const closeMenu = () => {
  const { menu, overlay, open } = menuParts()

  menu.setAttribute('aria-expanded', false)
  menu.classList.remove('active')
  overlay.classList.remove('active')

  open.focus()
}

const addBindings = () => {
  const { open, close, overlay } = menuParts()

  open.onclick = openMenu
  close.onclick = closeMenu
  overlay.onclick = closeMenu
}

const menuItems = menu => {
  let items = ``

  for (const item of menu) {
    items += `<li><a href="${ item.url }">${ item.text }</a></li>`
  }

  return items
}


const signInLink = () => {
  const { signIn, signOut, userIsAuthenticated } = config

  const { url, text } = (userIsAuthenticated === true)
        ? signOut
        : signIn

  return `
  <a href="${ url }" id="sgsignIn" class="sgButton dark">
    ${ text }
  </a>
  `
}


const menuHTML = () => {
  return `
    <button id="sgMenuOpen" class="sgButton sgDark" aria-expanded="false" aria-controls="sgMenu">
      My Account
    </button>
    <nav id="sgMenu">
      <ul>
        <li>
          <button id="sgMenuClose">
            Close 
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M345 137c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-119 119L73 103c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l119 119L39 375c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l119-119L311 409c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-119-119L345 137z"></path></svg>
          </button>
        </li>
        ${ menuItems(config.menu) }
      </ul>
    </nav>
    <div id="sgOverlay"></div>
  `
}


const html = () => {
  return config.userIsAuthenticated && config.menu.length > 0
    ? menuHTML()
    : signInLink()
}


export default {
  config,
  allowed,
  html,
  addBindings
}